'use strict';

require('../assets/remedy-player.css');
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from '../src/authConfig';

if (`${process.env["REACT_APP_SKIP_FRONTEND"]}` === "true") {
    console.log("Skipping FrontEnd - " + `${process.env["REACT_APP_SKIP_FRONTEND"]}`);
} else {
    /**
     * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
     * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
     */
    console.log("ClientID")
    console.log(`${process.env["REACT_APP_AAD_APP_CLIENT_ID"]}`)
    console.log("Loaded!")
    const msalInstance = new PublicClientApplication(msalConfig);
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    // Set active acccount on page load
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event) => {
        // set active account after redirect
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
            const account = event.payload.account;
            msalInstance.setActiveAccount(account);
        }
    }, error => {
        console.log('error', error);
    });


    // handle auth redired/do all initial setup for msal
    msalInstance.handleRedirectPromise().then(authResult => {
        // Check if user signed in 
        const account = msalInstance.getActiveAccount();
        if (!account) {
            // redirect anonymous user to login page 
            msalInstance.loginRedirect();
        } else {
            loadElmApp();
        }
    }).catch(err => {
        // TODO: Handle errors
        console.log(err);
    });


    if (window.matchMedia("(prefers-contrast: high)").matches
        || window.matchMedia("(prefers-color-scheme: dark)").matches) {
        require('../assets/bootswatch/darkly/bootstrap.min.css');
    }
    else {
        require('../assets/bootswatch/powerlift/bootstrap.min.css');
    }

    require('./gym.css');  // these rules take precedence over bootstrap

    var makeUuid = require('uuid').v4;

    window.noZensmooth = true;
    var zenscroll = require('../node_modules/zenscroll/zenscroll-min.js');

    var createObserver = require('./createObserver');

    var Plotly = require('../node_modules/plotly.js-basic-dist/plotly-basic.js');

    var USER_LOCAL_STORAGE_KEY = 'userV3';

    var uuidPool = [];
    for (var i = 0; i < 50; i++) {
        uuidPool.push(makeUuid())
    }


    var flags = {
        cachedUser: loadUserFromLocalStorage(),
        uuidPool: uuidPool,
        accessToken: "He"
    };

    function getAccessToken() {
        var request = {
            scopes: [`${process.env["REACT_APP_AAD_APP_CLIENT_ID"]}` + "/.default"]
        }
        return msalInstance.acquireTokenSilent(request).then(tokenResponse => {
            return tokenResponse.accessToken;
        });
    }
    function loadElmApp() {

        getAccessToken().then((token) => {
            flags['accessToken'] = token;
            var Elm = require('../src/App/Main.elm');
            var app = Elm.Elm.App.Main.init({
                flags: flags
            });

            app.ports.trackPageView.subscribe(function (pageInfo) {
                //appInsights.trackPageView({ name: pageInfo.title, uri: pageInfo.url });
            });

            app.ports.scrollIntoView.subscribe(function (id) {
                var element = document.getElementById(id);
                if (element === null) {
                    console.log("Could not scroll to element " + id + " because not found");
                    return;
                }
                element.scrollIntoView()
            });

            // Elm doesn't have a convenient way to get the current document URL, so we use a port.
            app.ports.getDocumentURL.subscribe(function () {
                app.ports.receiveDocumentURL.send(document.URL);
            });

            // Elm doesn't have a convenient way to get the Access Token, so we use a port.

            app.ports.getAccessToken.subscribe(function () {
                app.ports.receiveAccessToken.send(token);
            });
            window.onscroll = function () {
                var scrollBottom = window.innerHeight + Math.ceil(window.pageYOffset + 1);
                var height = document.body.offsetHeight;
                app.ports.windowScroll.send([scrollBottom, height]);
            };
            app.ports.cacheUser.subscribe(function (user) {
                if (!user) {
                    // User is logging out
                    try {
                        window.localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
                    } catch (e) {
                        console.error("failed to write to local storage: " + e);
                    }
                    return;
                }

                var tenantId;

                if (user.tenants && user.tenants.length) {
                    var activeTenant = null;
                    for (var i = 0, len = user.tenants.length; i < len; ++i) {
                        var t = user.tenants[i];
                        if (t.active) {
                            activeTenant = t;
                            break;
                        }
                    }

                    if (activeTenant) {
                        tenantId = activeTenant.name;   // the ID is too opaque
                    }
                }

                try {
                    // AppInsights will ignore the data unless we sanitize it first.  Tenant names with spaces in them have
                    // been getting dropped.
                    tenantId = tenantId.replace(/[\s,;|=]/g, "_");
                } catch (e) {
                    console.warn("Error while sanitizing tenantId", e)
                }

                //appInsights.setAuthenticatedUserContext(user.upn, tenantId, /* storeInCookie */ true);

                try {
                    window.localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
                } catch (e) {
                    // Either the browser is crazy-old or a localStorage limit has been reached
                    console.error("failed to write to local storage: " + e);
                }
            });
        });


    }

    function loadUserFromLocalStorage() {
        var hasStorage;
        try {
            var x = "__storage_test__";
            window.localStorage.setItem(x, x);
            window.localStorage.removeItem(x);
            hasStorage = true;
        } catch (e) {
            hasStorage = false;
        }

        if (hasStorage) {
            var data = window.localStorage.getItem(USER_LOCAL_STORAGE_KEY);
            if (data) {
                try {
                    return JSON.parse(data);
                } catch (e) {
                    console.error("failed to parse user from local storage");
                    window.localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
                }
            }
        }

        return null;
    }




    // Use MutationObserver to wrap JS charts lib
    // This should be changed to Custom Elements once browser support (IE11!) improves.


    // The DOM node ID which Elm will use to render JS charts
    var elm2jsNodeId = "#js-elm-chart";

    // The DOM attribute which Elm will use to send chart data/config to JS for display.
    var elm2jsDataAttributeName = "data-js-elm-chart";


    createObserver({
        rootElement: document.body,
        selector: elm2jsNodeId,
        onMount: mountChart,
        onUnmount: unmountChart,
        onAttrChanged: chartAttrChanged
    });


    function mountChart(node) {
        var chartContainer = document.createElement("div");
        node.appendChild(chartContainer);
        node._chartContainer = chartContainer;
        displayChart(node, chartContainer);
    }


    function unmountChart(node) {
        Plotly.purge(node._chartContainer);
    }


    function chartAttrChanged(attrName, attrTarget) {
        if (attrName === elm2jsDataAttributeName) {
            displayChart(attrTarget, attrTarget._chartContainer);
        }
    }


    function displayChart(node, chartContainer) {
        var dataFromElm = JSON.parse(node.getAttribute(elm2jsDataAttributeName));
        var rawChartData = dataFromElm.chart;
        var annotations = dataFromElm.timelineAnnotations;
        var percentageFormat = dataFromElm.percentageFormat;

        var xs = rawChartData.timeStamps.map(function (t) {
            return new Date(t)
        });

        var chartData = rawChartData.dataSets.map(function (ds) {
            return {
                type: "scatter",
                mode: "lines+markers",
                name: ds.label,
                x: xs,
                y: ds.values,
                line: {
                    width: 1,
                    color: ds.color
                }
            }
        });

        if (annotations) {
            var maxDataY = chartData.reduce(function (acc, trace) {
                return Math.max(acc, getArrayMax(trace.y))
            }, 0);

            for (var i = 0; i < annotations.length; i++) {
                var annotation = annotations[i];
                chartData.push({
                    type: "scatter",
                    mode: "lines+text",
                    showlegend: false,
                    name: annotation.text,
                    x: [annotation.date, annotation.date],
                    y: [0, maxDataY],
                    text: ["", annotation.text],
                    textposition: "top",
                    textfont: {
                        color: "red"
                    },
                    line: {
                        width: 1,
                        color: "red"
                    }
                })
            }
        }

        var chartLayout = {
            title: rawChartData.title,
            hovermode: 'closest',
            hoverlabel: {
                namelength: -1
            }
        };

        if (percentageFormat) {
            chartLayout['yaxis'] = { tickformat: ',.1%' }
        }

        var plotlyOptions = {
            responsive: true,
            modeBarButtonsToRemove: [
                'toImage', 'sendDataToCloud', 'toggleSpikelines', 'resetScale2d', 'select2d', 'lasso2d'
            ]
        };

        Plotly.react(chartContainer, chartData, chartLayout, plotlyOptions);
    }

    // Returns the maximum value in the array, or 0 if it is empty.
    function getArrayMax(arr) {
        return arr.reduce(function (acc, x) {
            return Math.max(acc, x);
        }, 0);
    }
}