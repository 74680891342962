// IE 11 doesn't support `forEach` on NodeList.
// TODO delete this once we completely drop IE 11
if ('NodeList' in window && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}


/**
 * Initializes a DOM mutation observer that will run a hook when
 * a DOM node matching a selector is mounted or unmounted.
 *
 *   const myObs = createObserver({
 *     selector: "[data-ace-editor]",
 *     onMount: node => {},
 *     onUnmount: node => {},
 *     onAttrChanged: node => {}
 *   });
 *
 * From: https://gist.github.com/pablen/c07afa6a69291d771699b0e8c91fe547
 */

var createObserver = function (config) {
    var rootElement = config.rootElement || document;
    var onMount = config.onMount;
    var onUnmount = config.onUnmount;
    var onAttrChanged = config.onAttrChanged;
    var observerConfig = config.observerConfig || {childList: true, subtree: true, attributes: !!onAttrChanged};
    var selector = config.selector;

    var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {

            // Handle "StayFocusd" Browser extension mutations. Elm Runtime throws exceptions with the plugin enabled.
            // This is to delete the offending changes to Virtual DOM.
            if (rootElement.querySelector('#StayFocusd-infobar') != null) {
                rootElement.querySelector('#StayFocusd-infobar').remove()
                return;
            }

            // Handle attribute changes
            if (onAttrChanged && mutation.type === "attributes") {
                onAttrChanged(mutation.attributeName, mutation.target);
            }

            // Handle added nodes
            if (onMount) {
                mutation.addedNodes.forEach(function (addedNode) {
                    var matchingElements = getMatchingElementsFromTree(addedNode, selector);
                    if (matchingElements.length < 1)
                        return;
                    matchingElements.forEach(function (node) {
                        onMount(node)
                    });
                });
            }

            // Handle removed nodes
            if (onUnmount) {
                mutation.removedNodes.forEach(function (removedNode) {
                    var matchingElements = getMatchingElementsFromTree(removedNode, selector);
                    if (matchingElements.length < 1)
                        return;
                    matchingElements.forEach(function (node) {
                        onUnmount(node)
                    });
                });
            }
        });
    });

    observer.observe(rootElement, observerConfig);

    return observer;
};

// Returns an iterator containing elements that were part of a DOM mutation & matches the selector
var getMatchingElementsFromTree = function (rootElement, selector) {
    return rootElement.querySelectorAll && rootElement.matches
        ? rootElement.matches(selector) ? [rootElement] : rootElement.querySelectorAll(selector)
        : [];
};

module.exports = createObserver;